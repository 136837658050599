export function map (n, start1, stop1, start2, stop2) {
  return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2
}

export function constrain (n, low, high) {
    return Math.max(Math.min(n, high), low)
}

export function mapConstrain (n, start1, stop1, start2, stop2) {
    const start = start2 !== undefined ? start2 : start1
    const stop = stop2 !== undefined ? stop2 : stop1
    return constrain(map(n, start1, stop1, start, stop), start, stop)
}

export function between (value, from, to) {
    return value <= to && value >= from
}

export function less (value, to) {
    return value < to
}

export function more (value, from) {
    return value > from
}


export function lerp (start, stop, amt) {
  return amt*(stop-start)+start;
};

export function randomBetween (min, max) {
    return Math.floor(Math.random() * max) + min
}

export function random (min, max) {
    return Math.random() * (max - min) + min
}

export function dist(x1, y1, z1, x2, y2, z2) {
  if (arguments.length === 4) {
    // In the case of 2d: z1 means x2 and x2 means y2
    return hypot(z1-x1, x2-y1);
  } else if (arguments.length === 6) {
    return hypot(x2-x1, y2-y1, z2-z1);
  }
}

export function shuffle (a) {
    for (let i = a.length; i; i--) {
        let j = Math.floor(Math.random() * i);
        [a[i - 1], a[j]] = [a[j], a[i - 1]];
    }
}

export function hypot(x, y, z) {
  // Use the native implementation if it's available
  if (typeof Math.hypot === 'function') {
    return Math.hypot.apply(null, arguments);
  }

  // Otherwise use the V8 implementation
  // https://github.com/v8/v8/blob/8cd3cf297287e581a49e487067f5cbd991b27123/src/js/math.js#L217
  var length = arguments.length;
  var args = [];
  var max = 0;
  for (var i = 0; i < length; i++) {
    var n = arguments[i];
    n = +n;
    if (n === Infinity || n === -Infinity) {
      return Infinity;
    }
    n = Math.abs(n);
    if (n > max) {
      max = n;
    }
    args[i] = n;
  }

  if (max === 0) {
    max = 1;
  }
  var sum = 0;
  var compensation = 0;
  for (var j = 0; j < length; j++) {
    var m = args[j] / max;
    var summand = m * m - compensation;
    var preliminary = sum + summand;
    compensation = (preliminary - sum) - summand;
    sum = preliminary;
  }
  return Math.sqrt(sum) * max;
}
