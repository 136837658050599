import TextRandomizerController from '@/js/TextRandomizerController'
import Event from '@/js/Event'
import Delay from '@/js/Delay'
export default class TextRandomizer extends Event{
    constructor ({text='',speed, id} = {}) {
        super()
        Object.assign(this, {text,speed, id})
        this.initialize()
    }
    initialize () {
        this.initialString = this.text
        this.previousString = ''
        this.currentString = this.text
        this.controller = new TextRandomizerController({text: this.text, speed:this.speed, id: this.id})
    }
    transitionToText (text, {startTime=0.075, changeTime=0.075, stopTime=0.075, startDelay=0, changeDelay=0, stopDelay=0} = {}, done) {
        Delay.add(startDelay, () => this.controller.startWhenPossible(startTime, () => {
            Delay.add(changeDelay, () => this.controller.changeString(text, changeTime, () => {
                Delay.add(stopDelay, () => this.controller.stopWhenPossible(stopTime, done), this.id)
            }), this.id)
        }), this.id)
    }

    changeTextToInitial (time) {
        this.controller.changeString(this.initialString, time)
    }
    changeText (string, time, done) {
        this.controller.changeString(string, time, done)
    }
    start () {
        this.controller.start()
    }
    stop () {
        this.controller.stop()
    }
    reset () {
        Delay.kill(this.id)
        this.controller.reset()
    }
    startWhenPossible(time, done) {
        this.controller.startWhenPossible(time, done)
    }
    stopWhenPossible(time, done) {
        this.controller.stopWhenPossible(time, done)
    }
    // forceStop () {
    //     this.controller.forceStop()
    // }
    get starting () {
        return this.controller.starting
    }
    get stopping () {
        return this.controller.stopping
    }
    render () {
        this.previousString = this.currentString
        this.currentString = this.controller.string
        if(this.previousString !== this.currentString) {
            this.$emit('updated', {string: this.currentString})
        }
    }
    get length () {
        return this.currentString.length
    }
}
