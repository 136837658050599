import { render, staticRenderFns } from "./Snake.vue?vue&type=template&id=7005f1a4&scoped=true&"
import script from "./Snake.vue?vue&type=script&lang=js&"
export * from "./Snake.vue?vue&type=script&lang=js&"
import style0 from "./Snake.vue?vue&type=style&index=0&id=7005f1a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7005f1a4",
  null
  
)

component.options.__file = "Snake.vue"
export default component.exports