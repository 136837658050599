import Event from "@/js/Event";
import InteractiveState from '@/js/InteractiveState'
export default class Interactive extends Event {
    constructor(element, args = {useInteractiveState: true}) {
        super();
        Object.assign(this, args)
        this.lastPosition = { x: 0, y: 0 };
        this.initialPosition = { x: 0, y: 0 };
        this.isUserActive = false;
        this.element = element;

        if (this.isTouch) {
            this.element.addEventListener("touchstart", this.handleStart.bind(this), false);
            this.element.addEventListener("touchmove", this.handleMove.bind(this), false);
            window.addEventListener("touchend", this.handleEnd.bind(this), false);
        } else {
            this.element.addEventListener("mousedown", this.handleStart.bind(this), false);
            this.element.addEventListener("mousemove", this.handleMove.bind(this), false);
            window.addEventListener("mouseup", this.handleEnd.bind(this), false);
        }
    }

    update() {}

    handleStart(evt) {
        const position = this.position(evt);
        this.lastPosition = {
            x: position.x,
            y: position.y
        };
        this.initialPosition = {
            x: position.x,
            y: position.y
        };
        this.isUserActive = true;
        this.$emit("on", {position});
    }
    handleMove(evt) {
        if (!this.isUserActive || (InteractiveState.UIActive && this.useInteractiveState)) return;
        evt.preventDefault();
        const position = this.position(evt);
        const x = (this.lastPosition.x - position.x) * -1;
        const y = (this.lastPosition.y - position.y) * -1;
        const initial = {
            x: this.initialPosition.x - position.x,
            y: this.initialPosition.x - position.x
        };
        this.$emit("progress", { x, y, position, initial });
        this.lastPosition = {
            x: position.x,
            y: position.y
        };
    }
    handleEnd(evt) {
        if (!this.isUserActive) return;
        const position = this.position(evt);
        this.lastPosition = {
            x: position.x,
            y: position.y
        };
        this.$emit("off");
        this.isUserActive = false;
    }
    position(evt) {
        return {
            x: evt.targetTouches && evt.targetTouches.length ? evt.targetTouches[0].pageX : evt.pageX,
            y: evt.targetTouches && evt.targetTouches.length ? evt.targetTouches[0].pageY : evt.pageY
        };
    }
    get isTouch() {
        return "ontouchstart" in window || navigator.maxTouchPoints;
    }
    destroy () {
        this.element.removeEventListener("touchstart", this.handleStart.bind(this), false);
        this.element.removeEventListener("touchmove", this.handleMove.bind(this), false);
        window.removeEventListener("touchend", this.handleEnd.bind(this), false);
        this.element.removeEventListener("mousedown", this.handleStart.bind(this), false);
        this.element.removeEventListener("mousemove", this.handleMove.bind(this), false);
        window.removeEventListener("mouseup", this.handleEnd.bind(this), false);
    }
}
