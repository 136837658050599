export default class Ease{
	constructor (opts = {}) {
 		this.target = 0
        this.position = 0
 		this.value = opts.start || 0
 		this.ease = opts.ease || 0.075
 		this.limit = opts.limit || 100
    }
	update (){
        const dx = this.target - this.position
        this.position += dx * this.ease
	}
}

export class Ease2D {
    constructor (opts = {}) {
        this.x = new Ease(opts)
        this.y = new Ease(opts)
    }
    update (x, y) {
        return {
            x: this.x.update(x),
            y: this.y.update(y)
        }
    }
}
