
export default {
	namespaced: true,
	state: {
		homeVisited: false,
	},
	mutations: {
		setHomeVisited (state, value) {
			state.homeVisited = value
		},
	},
}
