<template>
    <div class="text-randomizer-link" @mouseenter="mouseenter" @mouseleave="mouseleave">
        <span v-for="string in strings">{{string}}</span>
    </div>
</template>
<script>
import TextRandomizer from '@/js/TextRandomizer'
import Render from '@/js/Render'
import Delay from '@/js/Delay'
export default {
    name: "TextRandomizerLink",
    components: {
    },
    props: {
        fast: Boolean,
        text: String,
        hoverText: String,
    },
    mixins: [],
    data () {
        return {
            shouldDissolve: false,
            strings: [],
        }
    },
    computed: {
        id () {
            return this.text + this.hoverText
        },
        emptyString () {
            let str = ''
            for (var i = 0; i < this.text.length; i++) { str += ' ' }
            return str
        }
    },
    mounted () {
        this.textRandomizer = new TextRandomizer({text: this.emptyString, speed: 0.25, id: this.id})
        this.strings = this.textRandomizer.currentString.split('')
        this.textRandomizer.$on('updated', this.updated)
        Render.add(this.update)
        Delay.add(!this.fast ? 3.5 : 0.5, () => this.initialize())
    },
    methods: {
        initialize () {
            this.textRandomizer.transitionToText(this.text)
        },
        mouseenter () {
            if(this.shouldDissolve) return
            Delay.kill(this.id)
            this.textRandomizer.transitionToText(this.hoverText, {
                startTime:0.025, changeTime:0.025, stopTime:0.025
            })
        },
        mouseleave () {
            if(this.shouldDissolve) return
            Delay.kill(this.id)
            this.textRandomizer.transitionToText(this.text, {
                startTime:0.025, changeTime:0.025, stopTime:0.025
            })
        },
        update () {
            this.textRandomizer.render()
        },
        updated (e) {
            this.strings = e.string.split('')
        },
        dissolve (done) {
            this.shouldDissolve = true
            Delay.kill(this.id)
            this.textRandomizer.transitionToText(this.emptyString, {}, done)
        },
    },
    watch: {
    },
    beforeDestroy() {
        Render.remove(this.update)
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.text-randomizer-link{
    display: inline-block;
    font-size: rem(10px);
    text-transform: uppercase;
    opacity: 0.25;
    transition: opacity 1s ease;
    cursor: pointer;
    // letter-spacing: 0.5em;
    @include up {
        &:hover{
            opacity: 1;
        }
    }
    &.active{
        opacity: 1;
    }
    span{
        width: 1em;
        display: inline-block;
        text-align: center;
    }
}
</style>
