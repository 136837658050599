import Event from '@/js/Event'
export default class Canvas extends Event{
    constructor ({el} = {}) {
        super()
        Object.assign(this, {el})
        this.initialize()
        this.setupCanvas()
    }
    initialize () {
        this.ti = 0
        this.canvas = document.createElement('CANVAS')
        this.ctx = this.canvas.getContext('2d')
        this.devicePixelRatio = window.devicePixelRatio || 1
        this.el.appendChild(this.canvas)
    }
    setupCanvas () {
        this.setDimensions()
    }
    setDimensions () {
        this.canvas.width = this.realWidth
        this.canvas.height = this.realHeight
        this.canvas.style.width = `${this.width}px`
        this.canvas.style.height = `${this.height}px`
    }
    resize () {
        clearTimeout(this.ti)
        this.ti = setTimeout(() => {
            this.setDimensions()
            this.$emit('resized')
        }, 250)
    }
    get width () {
        return window.innerWidth
    }
    get height () {
        return window.innerHeight
    }
    get realWidth () {
        return this.width * this.devicePixelRatio
    }
    get realHeight () {
        return this.height * this.devicePixelRatio
    }
 }
