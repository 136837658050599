import Delay from '@/js/Delay'
import {getAbsolutePosition} from '@/js/Utils'
import {random} from '@/js/Matematik'
export default class MixCharacter {
	constructor (character, char) {
		this.character = character
		this.char = char
		this.character.innerHTML = char === ' ' ? '&nbsp;' : char
		this.el = this.character
		TweenMax.set(this.character, {
			visibility: 'hidden',
		})
	}
	show () {
		TweenMax.set(this.character, {
			visibility: 'visible',
			x: 0,
			y: 0,
			xPercent: 0,
			yPercent: 0,
			opacity: 1
		})
	}
	hide () {
		TweenMax.set(this.character, {
			visibility: 'hidden',
		})
	}
	in (done) {
		const bool = Math.random() < 0.5
		TweenMax.fromTo(this.character, 0.5, {
			opacity: 0,
			x: 0,
			y: 0,
			xPercent: bool ? Math.random() < 0.5 ? -25 : 25 : 0,
			yPercent: bool ? 0 : Math.random() < 0.5 ? -25 : 25,
			visibility: 'visible',
		}, {
			opacity: 1,
			x: 0,
			y: 0,
			xPercent: 0,
			yPercent: 0,
			ease: Power3.easeInOut,
			onComplete: done
		})
	}
	out () {
		const bool = Math.random() < 0.5
		return new Promise((resolve, reject) => {
			TweenMax.to(this.character, 0.5, {
				opacity: 0,
				x: 0,
				y: 0,
				xPercent: bool ? Math.random() < 0.5 ? -25 : 25 : 0,
				yPercent: bool ? 0 : Math.random() < 0.5 ? -25 : 25,
				ease: Power3.easeInOut,
				onComplete: resolve
			})
		})
	}
	get top (){
		const pos = getAbsolutePosition(this.character)
		return pos.y //this.character.offsetTop
	}
	get left () {
		const pos = getAbsolutePosition(this.character)
		return pos.x //this.character.offsetLeft
	}
	to ({target}, done) {
		const p1 = {x: 0, y: 0}
		const p2 = {x: target.left - this.left, y: target.top - this.top}
		const path = this.getSquarePath(p1, p2)
		const time = Math.max(this.dist(p1, p2) * 0.005, 0.75)
		return new Promise((resolve, reject) => {
			TweenMax.to(this.character, time, {
				bezier:{
					curviness:0,
					values: path,
				},
				ease: Power1.easeInOut,
				onComplete: resolve
			})
		})
	}
	getSquarePath (p1, p2) {
		// const p3 = this.getPointAtLength(p1, p2, random(0.25, 0.75) )
		// const xInc = 2.25
		// const yInc = 3
		// p3.x = Math.round(p3.x / xInc) * xInc
		// p3.y = Math.round(p3.y / yInc) * yInc
		const bool = Math.random() > 0.5
		return bool ? [
			{x: p2.x, y: 0},
			{x: p2.x, y: p2.y},
		] : [
			{x: 0, y: p2.y},
			{x: p2.x, y: p2.y},
		]
		// return bool ? [
		// 	{x: p3.x, y: 0},
		// 	{x: p3.x, y: p2.y},
		// 	{x: p2.x, y: p2.y},
		// ] : [
		// 	{x: 0, y: p3.y},
		// 	{x: p2.x, y: p3.y},
		// 	{x: p2.x, y: p2.y},
		// ]
	}
	getPointAtLength (p1, p2, d) {
		return {
			x: p1.x + (p2.x - p1.x) * d,
			y: p1.y + (p2.y - p1.y) * d,
		}
	}
	dist (p1, p2) {
		return Math.hypot(p2.x - p1.x, p2.y - p1.y)
	}
}
