import TextRandomizerControllerObject from '@/js/TextRandomizerControllerObject'
import Delay from '@/js/Delay'
import Queue from '@/js/Queue'
import {shuffle} from '@/js/Matematik'

export default class TextRandomizerController {
	constructor({text = '',speed,id} = {}) {
		Object.assign(this, {text,speed,id})
		this.initialize()
	}
	initialize() {
		window.trc = this
		this.chars = []
		this.currentText = this.text
		this.modifyQueue = new Queue()
		this.queue = new Queue()
		this.text.split('').forEach(item => {
			const trco = new TextRandomizerControllerObject({
				text: item,
				speed: this.speed,
			})
			this.chars.push(trco)
		})
	}
	changeString(string, time = 0.1, complete) {
		this.modifyQueue.flush()
		this.currentText = string
		const s = this.currentText.split('')
		const diff = s.length - this.chars.length
		const l = Math.abs(diff)
        this.overrideCharText()
		for (let i = 0; i < l; i++) {
			if (diff < 0) {
				this.modifyQueue.add(g => {
					Delay.add(time, () => {
						g(done => {
							if (this.currentText.length < this.chars.length) {
								this.chars.pop()
							}
							done()
						})
					}, this.id)
				})
			} else {
                const trco = new TextRandomizerControllerObject({
                    text: '',
                    speed: this.speed,
                })
                trco.running()
				this.modifyQueue.add(g => {
					Delay.add(time, () => {
						g(done => {
							if (this.currentText.length > this.chars.length) {
								this.chars.push(trco)
							}
							done()
						})
					}, this.id)
				})
			}
		}
		if (!this.modifyQueue.executing) {
            this.modifyQueue.run(() => {
                this.overrideCharText()
				complete()
    		})
        }
	}
    overrideCharText () {
        const s = this.currentText.split('')
        this.chars.forEach((char, idx) => char.text = s[idx])
    }
	whenPossible (action, time = 0.1, complete) {
		this.queue.flush()
		this.chars.forEach((char, idx) => {
			this.queue.add(g => {
				const t = !this.queue.length ? 0 : time
				Delay.add(t, () => {
					g(done => {
						char[action]()
						done()
					})
				}, this.id)
			})
		})
		shuffle(this.queue.queue)
		this.queue.run(complete)
	}
	startWhenPossible (time, complete) {
		this.whenPossible('running', time, complete)
	}
	stopWhenPossible(time, complete) {
		this.whenPossible('stop', time, complete)
	}
	start() {
		this.chars.forEach(item => item.start())
	}
	stop() {
		this.chars.forEach(item => item.stop())
	}
	reset () {
		Delay.kill(this.id)
		this.queue.flush()
		this.modifyQueue.flush()
	}
	get string() {
		return this.chars.reduce(this.reducer, '')
	}
	reducer(acc, cur) {
		return acc + (cur.char || '')
	}
}
