<template>
    <div class="key" :class="classes">
        <div class="key__graphic">
            <span class="key__char" v-if="char">{{char}}</span>
            <span class="key__symbol" v-if="symbol" :class="['key__symbol--' + symbol]"></span>
        </div>
    </div>
</template>
<script>
import Keyboard from '@/js/Keyboard'
export default {
    name: "Key",
    components: {},
    props: {
        char: String,
        symbol: String,
        disabled: Boolean,
        code: String
    },
    mixins: [],
    data () {
        return {
            highlight: false
        }
    },
    computed: {
        classes () {
            return {
                disabled: this.disabled,
                highlight: this.highlight
            }
        }
    },
    mounted () {
        Keyboard.$on(`${this.code}/down`, this.keydown)
        Keyboard.$on(`${this.code}/up`, this.keyup)
    },
    methods: {
        keydown () {
            this.highlight = true
        },
        keyup () {
            this.highlight = false
        },
    },
    watch: {},
    beforeDestroy () {
    }
 }
</script>
<style lang="scss" scoped>
@import "src/scss/index.scss";
.key{
    opacity: .4;
    pointer-events: none;
    margin: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled{
        opacity: 0.2;
    }
    &.highlight:not(.disabled){
        opacity: 1;
    }
}
.key__graphic{
    min-width: 1rem;
    height: 1rem;
    border: 1px solid rgba($color-white, .5);
    border-bottom-width: 2px;
    border-radius: rem(2px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.1rem;
}
.key__char{
    font-size: rem(10px);
    text-transform: uppercase;
    opacity: 1;
}
.key__symbol{
    &:before{
        content:"";
        border-left: 1px solid;
        border-top: 1px solid;
        width: 0.2rem;
        height: 0.2rem;
        display: block;
        transform-origin: 50% 50%;
    }
    &--up:before{ transform: translate(-.25px, 1px) rotate(45deg); }
    &--left:before{ transform: translate(1px, -.25px) rotate(-45deg); }
    &--down:before{ transform: translate(-.25px, -1px) rotate(-135deg); }
    &--right:before{ transform: translate(-1px, -.25px) rotate(135deg); }
}
</style>