import Noise from '@/js/Noise'
import Event from '@/js/Event'
function random(min, max){
	return Math.random() * (max-min) + min;
}

export default class Particle extends Event{
    constructor ({canvas,w,h} = {}) {
        super()
        Object.assign(this, {canvas,w,h})
        this.initialize()
    }
    initialize () {
        const c = parseInt(random(25,75))
        this.position = {x:random(0, this.widthBoundary), y:random(0, this.heightBoundary)}
    	this.velocity = {x:0, y:0}
    	this.color = `rgb(${c},${c},${c})`
    	this.spe = random(0.1, 0.5) * this.canvas.devicePixelRatio
    	this.cur = random(1, 1.25) * this.canvas.devicePixelRatio
    	this.dim = random(1, 3) * this.canvas.devicePixelRatio
    	this.amp = window.innerHeight
    }
    update() {
		const noiseX = this.cur + this.position.y / this.amp;
		const noiseY = this.cur + this.position.x / this.amp;
		this.velocity.x = this.spe * (Noise.perlin2(noiseX, 0) - 0.5);
	    this.velocity.y = this.spe * (Noise.perlin2(noiseY, 0) - 0.5);
	    this.position.x += this.velocity.x;
	    this.position.y += this.velocity.y;

	    if(this.position.x < 0) this.position.x += this.widthBoundary;
	    if(this.position.x > this.widthBoundary) this.position.x -= this.widthBoundary;
	    if(this.position.y < 0) this.position.y += this.heightBoundary;
	    if(this.position.y > this.heightBoundary) this.position.y -= this.heightBoundary;
	}
    draw () {
        this.canvas.ctx.fillStyle = this.color
		this.canvas.ctx.fillRect(this.position.x, this.position.y, this.dim, this.dim)
    }
	render () {
		this.update()
		this.draw()
	}
    destroy () {
        console.log('Should destroy?')
    }
    get widthBoundary () {
        return this.canvas.realWidth
    }
    get heightBoundary () {
        return this.canvas.realHeight
    }
}

/*
function Particle(opts){
	var self = {};
	var opts = opts || {};
	var ctx = opts.ctx;
	var canvas = opts.canvas;
	var position = {x:0, y:0};
	var velocity = {x:0, y:0};
	var c = parseInt(random(25,75));
	var color = 'rgb('+c+','+c+','+c+')';
	var spe = random(0.1, 0.5);
	var cur = random(1, 1.25);
	var dim = random(1, 3);
	var amp = window.innerHeight;


	function init(){
		position.x = random(0, window.innerWidth);
		position.y = random(0, window.innerHeight);
	}

	function render(){
		update();
		draw();
	}

	function update(){
		var noiseX = cur + position.y / amp;
		var noiseY = cur + position.x / amp;
		velocity.x = spe * (Noise.perlin2(noiseX, 0) - 0.5);
	    velocity.y = spe * (Noise.perlin2(noiseY, 0) - 0.5);
	    position.x += velocity.x;
	    position.y += velocity.y;

	    if(position.x < 0) position.x += window.innerWidth;
	    if(position.x > window.innerWidth) position.x -= window.innerWidth;
	    if(position.y < 0) position.y += window.innerHeight;
	    if(position.y > window.innerHeight) position.y -= window.innerHeight;
	}


	function draw(){
		// ctx.beginPath();
		// ctx.arc(position.x, position.y, dim, 0, 2*Math.PI);
		// ctx.fillStyle = color;
		// ctx.fill();
		ctx.fillStyle = color;
		ctx.fillRect(position.x, position.y, dim, dim);
	}

	function destroy(){
		self = null;
	}

	self.render = render;
	self.destroy = destroy;

	init();

	return self;
}
*/
