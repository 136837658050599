<template>
<div class="projects">
	<Gallery :slides="selectedProjects" />
</div>
</template>
<script>
import Gallery from '@/components/Gallery'
import projects from '@/data/projects'
export default {
	name: "Featured",
	components: {
		Gallery,
	},
	props: {},
	mixins: [],
	data() {
		return {
			projects
		}
	},
	computed: {
        selectedProjects () {
            return this.projects[this.$route.params.slug]
        }
    },
	mounted() {},
	methods: {},
	watch: {},
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
