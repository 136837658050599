<template>
    <div class="home">
        <div class="home__inner">
            <div class="home__heading">
                <TextRandomizerCarousel :texts="texts" ref="trc" :fast="homeVisited"/>
            </div>
            <div class="home__links">
                <div class="home__link" @touchend="goto(link)" v-for="link in links" :key="link" :data-slug="link">
                    <TextRandomizerLink :text="link" hover-text="explore" ref="links" :fast="homeVisited"/>
                </div>
            </div>
        </div>
        <div class="home__background" ref="background" :class="backgroundClass"></div>
        <div class="app__keyboard" v-if="desktop">
            <KeyboardComponent setup="nav" ref="keyboard"/>
        </div>
    </div>
</template>
<script>
/**
 * Possible fix is to goto new page before trc is finishing dissolving... (strange)
 * 
 * For some reason the last char of the two TextRandomizer is destroying the ios13
 * It doesnt matter which one it is. The last one to finish stop the app completely. 
 * 
 * Very very strange!! Works everywhere except browsers (Chrome and Safari) on iOS 13.
 * 
 * And it only occurs when there are more than 1 TextRandomizerLink. And you click on everything except the last link???
 * 
 * It work when you use a function to go to the link, and NOT clicking it!!!
 * 
 * Seems like it was VUE's fault or iOS clickhandling. touchends seems to work.
 */
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import ParticleSystem from '@/js/ParticleSystem'
import Render from '@/js/Render'
import Delay from '@/js/Delay'
import TextRandomizerCarousel from '@/components/TextRandomizerCarousel'
import TextRandomizerLink from '@/components/TextRandomizerLink'
import Gallery from '@/components/Gallery'
import KeyboardComponent from '@/components/Keyboard'
import Keyboard from '@/js/Keyboard'
import {isTouchDevice} from '@/js/Utils'
export default {
    name: "Home",
    components: {
        TextRandomizerCarousel,
        TextRandomizerLink,
        Gallery,
        KeyboardComponent,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
            backgroundActive: false,
            texts: [
                'adalberth',
                'front-end',
                'developer',
                'copenhagen',
            ],
            links: [
                'work', 
                'fun', 
                'about',
            ]
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['desktop']),
        ...mapState('interaction', ['homeVisited']),
        backgroundClass () {
            return {
                active: this.backgroundActive
            }
        }
    },
    mounted() {
        this.setupBackground()
        this.events()
        Render.add(this.render)
        Delay.add(0.25, () => this.backgroundActive = true)
        if(this.desktop) {
            TweenMax.delayedCall(this.homeVisited ? 1.5 : 4, () => {
                this.$refs.keyboard.animateIn()
            })
        }
        this.setHomeVisited(true)

        /**
         * TO FIC VERY STRANGE IOS 13 CLICK-EVENT BUG.
         * THE APP DOESNT WORK WHEN IOS 13 SAFARI HAS ANY CLICK EVENT ATTACHED!
         */
        if(!isTouchDevice()) {
            const links = [].slice.call(document.querySelectorAll('.home__link'))
            links.forEach(item => {
                item.addEventListener('click', () => {
                    this.goto(item.getAttribute('data-slug'))
                })
            })
        }
    },
	methods: {
        ...mapMutations('interaction', ['setHomeVisited']),
        setupBackground () {
            const {background} = this.$refs
            this.particleSystem = new ParticleSystem({el: background})
        },
		events() {
			this.resize()
            window.addEventListener('resize', this.resize)
            Keyboard.$on('w/down', this.keydown)
            Keyboard.$on('f/down', this.keydown)
            Keyboard.$on('a/down', this.keydown)
            // window.addEventListener('this.keydown', this.keydown)
        },
        keydown ({key}) {
            const {links} = this.$refs
            const map = {
                w: 0,
                f: 1,
                a: 2
            }
            const idx = map[key]
            links[idx].$el.classList.add('active')
            links[idx].mouseenter()
            Delay.add(1, () => {
                this.goto(this.links[idx])
            })
        },
		resize() {
            this.particleSystem.resize()
		},
        render () {
            this.particleSystem.render()
        },
        goto (slug) {
            const {trc, links} = this.$refs
            this.backgroundActive = false
            
            links.forEach(item => {
                item.dissolve()
            });
            trc.dissolve(() => {
                this.$router.push(slug.match(/work|fun/) ? {
                    name: 'Projects',
                    params: {slug}
                } : {name: 'About'})
            })
            if(this.desktop) this.$refs.keyboard.animateOut()
        }
	},
	beforeDestroy() {
        window.removeEventListener('resize', this.resize)
        Keyboard.$off('w/down', this.keydown)
        Keyboard.$off('f/down', this.keydown)
        Keyboard.$off('a/down', this.keydown)
        // window.removeEventListener('keydown', this.keydown)
        Render.remove(this.render)
	}
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.home{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home__inner{
    position: relative;
    z-index: 100;
}
.home__heading{
    text-align: center;
}
.home__links{
    margin-top: 2rem;
    text-align: center;
}
.home__link{
    display: inline-block;
    margin: 0rem 1rem;
}
.home__background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	z-index: $zindex-floor;
    opacity: 0;
    transition: opacity 2s ease;
    &.active{
        transition: opacity 5s ease;
        opacity: 1;
    }
    /deep/ canvas{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
