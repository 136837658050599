import TextRandomizerPool from '@/js/TextRandomizerPool'
export default class TextRandomizerControllerObject{
    constructor ({text='', speed=0.75} = {}) {
        Object.assign(this, {text, speed})
        this.initialize()
    }
    initialize () {
        this.speedCount = Math.floor(1 / this.speed)
        this.count = 0
        this.currentChar = this.text
        this.state = 'stop'
    }
    initial () {
        this.state = 'initial'
    }
    running () {
        this.state = 'running'
    }
    start () {
        this.running()
    }
    stop () {
        this.state = 'stop'
    }
    get isInitial () {
        return this.state === 'initial'
    }
    get isRunning () {
        return this.state === 'running'
    }
    get isStop () {
        return this.state === 'stop'
    }
    get char () {
        if(this.isInitial) {
            this.currentChar = ' '
        } else if(this.isRunning && this.count === 0) {
            this.currentChar = TextRandomizerPool.randomString(1, 'aA#!')
        } else if (this.isStop) {
            this.currentChar = this.text
        }
        this.count += 1
        this.count %= this.speedCount
        return this.currentChar
    }
}
