export default class Queue {
	constructor () {
		this.executing = false
		this.id = this.newid()
		this.queue = []
	}
	add (callback) {
		this.queue.push({
			id: this.id,
			callback
		})
	}
	run (complete=false) {
		this.executing = true
		this.loop(this.id, complete)
	}
	loop (id, complete) {
		if(id !== this.id) return
		const item = this.queue.splice(0, 1)[0] || false
		if (item) {
			const done = () => this.loop(id, complete)
			item.callback(this.guard(item.id, done))
		} else if (complete) {
			complete()
			this.executing = false
		}
	}
	guard (id, done) {
		return callback => id === this.id && callback(done)
	}
	flush () {
		this.executing = false
		this.id = this.newid()
		this.queue = []
	}
	newid () {
		return Date.now() + Math.random().toString(36).substring(2, 15)
	}
	get length () {
		return this.queue.length
	}
}

/*

const q = new Queue()

// Sync
q.add(guard => guard(done => {
    // do stuff
    done()
}))

// Async
q.add(guard => {
    setTimeout(() => {
        guard(done => {
            // do stuff
            done()
        })
    }, 1000)
})

// Run
q.run(() => {
    // Callback
    // When the queue is done
})

// Flush the queue
q.flush()

*/
