import Event from "@/js/Event";
class Keyboard extends Event {
    constructor() {
        super();
        this.keyDowns = {};
        document.addEventListener("keydown", this.keyDownHandler.bind(this), false);
        document.addEventListener("keyup", this.keyUpHandler.bind(this), false);
    }
    keyDownHandler({ key }) {
        // console.log('keyDownHandler', key);
        if (!this.keyDowns[key]) this.$emit(`${key}/down`, {key});
        this.keyDowns[key] = true;
    }
    keyUpHandler({ key }) {
        delete this.keyDowns[key];
        this.$emit(`${key}/up`, {key});
    }
    down(key, callback) {
        this.$on(`${key}/down`, callback);
    }
    up(key, callback) {
        this.$on(`${key}/up`, callback);
    }
    offDown(key, callback) {
        this.$off(`${key}/down`, callback);
    }
    offUp(key, callback) {
        this.$off(`${key}/up`, callback);
    }
}
const keyboard = new Keyboard();
export default keyboard;
