import '@/assets/fonts/font.scss';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

import {TweenMax} from 'gsap'
import '@/lib/gsap/CustomEase'
import '@/lib/gsap/DrawSVGPlugin'
import '@/js/CustomEase'

import EventBus from '@/js/EventBus'
Object.defineProperties(Vue.prototype, {$bus:{get: () => EventBus }})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
