<template>
    <div class="text-randomizer">
        <span v-for="string in strings">{{string}}</span>
    </div>
</template>
<script>
import TextRandomizer from '@/js/TextRandomizer'
import Render from '@/js/Render'
import Delay from '@/js/Delay'
export default {
    name: "TextRandomizerCarousel",
    components: {
    },
    props: {
        size: Number,
        fast: Boolean,
        texts: {
            type: Array,
            default () {
                return ['adalberth', 'front-end', 'developer', 'copenhagen']
            }
        },
    },
    mixins: [],
    data () {
        return {
            strings: [],
            shouldDissolve: false,
            id: 'trc'
        }
    },
    computed: {
    },
    mounted () {
        this.textRandomizer = new TextRandomizer({text: this.emptyString(this.texts[0]), speed: 0.25, id: this.id})
        this.strings = this.textRandomizer.currentString.split('')
        this.textRandomizer.$on('updated', this.updated)
        Render.add(this.render)
        this.initialize()
        window.tr = this.textRandomizer
    },
    methods: {
        initialize() {
            this.textRandomizer.transitionToText(this.texts[0], {
                startTime: !this.fast ? 0.25 : 0.05,
                changeDelay: !this.fast ? 0.5 : false,
            }, () => {
                this.loop(1)
            })
        },
        loop (i) {
            if(this.shouldDissolve) return
            const idx = i % this.texts.length
            this.textRandomizer.transitionToText(this.texts[idx],  {
                startDelay: 2,
            }, () => {
                if(this.shouldDissolve) return
                this.loop(idx + 1)
            })
        },
        dissolve (done) {
            this.shouldDissolve = true
            this.textRandomizer.reset()
            this.$nextTick(() => {
                this.textRandomizer.transitionToText('         ', {}, done)
            })
        },
        render () {
            this.textRandomizer.render()
        },
        updated (e) {
            this.strings = e.string.split('')
        },
        emptyString (string) {
            let str = ''
            for (var i = 0; i < string.length; i++) { str += ' ' }
            return str
        }
    },
    watch: {
    },
    beforeDestroy() {
        Render.remove(this.render)
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.text-randomizer{
    display: inline-block;
    font-size: rem(25px);
    @include up {
        font-size: rem(50px);
    }
    span{
        width: 1em;
        display: inline-block;
        text-align: center;
    }
}
</style>
