<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         :width="width" :height="height" :viewBox="viewBox" :enable-background="enableBackground" xml:space="preserve">
         <path fill="none" stroke="#404040" stroke-width="1" :d="snake" v-for="snake in snakes" ref="snakes"/>
    </svg>
</template>
<script>
import {convertRemToPixels} from '@/js/Utils'
import {random} from '@/js/Matematik'
export default {
    name: "Snake",
    components: {
    },
    props: {
    },
    mixins: [],
    data () {
        return {
            currentSnake: 0,
            w: window.innerWidth,
            h: window.innerHeight,
            snakes: []
        }
    },
    computed: {
        width () {
            return `${this.w}px`
        },
        height () {
            return `${this.h}px`
        },
        viewBox () {
            return `0 0 ${this.w} ${this.h}`
        },
        enableBackground () {
            return `new 0 0 ${this.w} ${this.h}`
        },
    },
    mounted () {
        this.initialize()
        this.$nextTick(() => {
            this.resetSnakes()
            this.animateSnake(0)
            this.animateSnake(1)
        })
    },
    methods: {
        initialize() {
            this.w = this.$el.parentNode.offsetWidth
            this.h = this.$el.parentNode.offsetHeight
            this.snakes = [
                this.createPath(),
                this.createPath(),
                this.createPath(),
                this.createPath(),
                this.createPath(),
                this.createPath(),
                this.createPath(),
                this.createPath(),
                this.createPath(),
                this.createPath(),
            ]
        },
        resetSnakes () {
            const {snakes} = this.$refs
            snakes.forEach(snake => {
                TweenMax.set(snake, {
                    drawSVG:"100% 125%",
                })
            })
        },
        animateSnake (idx) {
            const {snakes} = this.$refs
            const snake = snakes[idx]
            if(!snake) return
            const time = 3
            const tl = new TimelineMax({
                onComplete: () => {
                    this.currentSnake += 2
                    this.currentSnake %= this.snakes.length
                    this.animateSnake(this.currentSnake)
                }
            })
            tl.set(snake, {
                drawSVG:"100% 125%",
            })
            tl.to(snake, time * 4, {
                drawSVG:"0% 25%",
                ease: Power0.easeNone,
            })
            tl.to(snake, time, {
                drawSVG:"0% 0%",
                ease: Power0.easeNone,
            })
        },
        unitW (value) {
            return Math.round(value / this.wu()) * this.wu()
        },
        unitH (value) {
            return Math.round(value / this.hu()) * this.hu()
        },
        wu (value=1) {
            return convertRemToPixels(3) * value
        },
        hu (value=1) {
            return convertRemToPixels(2.25) * value
        },
        createPath () {
            let x = this.unitW(random(this.w * 0.4, this.w * 0.8))
            let y = 0
            let path = `M${x},${y}`
            let turn = 1
            while(x < this.w || y < this.h) {
                if(turn) {
                    y += this.hu(Math.floor(random(1, 10)))
                } else {
                    x += this.wu(Math.floor(random(1, 5)))
                }
                turn++
                turn %=2
                path += `L${x},${y}`
            }
            return path
        }
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
