<template>
    <div class="deck">
        <div class="deck__slides" :class="slidesClass" ref="slides">
            <div class="deck__slide" v-for="(slide, idx) of slides">
                <div class="deck__image" :class="{'active':(top ? idx : slides.length - 1 - idx) === index}" :style="{backgroundImage: 'url('+slide+')'}"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import {map} from '@/js/Matematik'
import Render from '@/js/Render'
import Ease from '@/js/Ease'
export default {
    name: "Deck",
    components: {
    },
    props: {
        index: Number,
        slides: Array,
        top: Boolean,
        bottom: Boolean,
    },
    mixins: [],
    data () {
        return {
            currentX: 0,
            offsetX: 0,
            animating: true,
            ease: new Ease()
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['desktop']),
        slidesClass () {
            return {
                'deck__slides--top': this.top,
                'deck__slides--bottom': this.bottom,
            }
        },
        direction () {
            return this.top ? -1 : 1
        }
    },
    mounted () {
        this.beforeInitialize()
        this.initialize()
        Render.add(this.render)
    },
    methods: {
        beforeInitialize () {
            const {slides} = this.$refs
            const increment = (1 / this.slides.length) * 100
            TweenMax.set(slides, {
                xPercent: increment * this.direction,
                force3D: true,
            })
        },
        initialize () {
            const {slides} = this.$refs
            TweenMax.to(slides, 3, {
                xPercent: 0,
                ease: Power4.easeInOut,
                // force3D: true,
                onComplete: () => this.animating = false
            })
        },
        render () {
            this.ease.update()
            if(this.animating) return
            const {slides} = this.$refs
            TweenMax.set(slides, {
                xPercent: this.ease.position,
                force3D: true,
            })
        },
        offset (pos) {
            const increment = (1 / this.slides.length)
            this.offsetX = pos * increment * 5
            this.ease.target = this.currentX + this.offsetX * (this.direction * -1)
        },
        move (pos) {
            const increment = (1 / this.slides.length)
            this.offsetX = increment * pos * 100
            this.ease.target = this.currentX + this.offsetX * (this.direction * - 1)
        }
    },
    watch: {
        index () {
            const {slides} = this.$refs
            const increment = (1 / this.slides.length) * 100
            this.currentX = this.index * increment * (this.direction * -1)
            this.ease.target = this.currentX
            this.animating = true
            const ease = this.desktop ? Power4.easeInOut : Power4.easeOut
            const time = this.desktop ? 3 : 1.5
            TweenMax.to(slides, time, {
                xPercent: this.currentX,
                ease,
                force3D: true,
                onComplete: () => {
                    this.animating = false
                    this.$emit('complete')
                }
            })
        },
    },
    beforeDestroy() {
      Render.remove(this.render)
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
$w: 100vw;
$h: 100vh;

.deck{
    height: $h * 0.5;
    @include down {
        height: calc(var(--vh, 1vh) * 50);
    }
    overflow: hidden;
    position: relative;
}
.deck__slides{
    display: flex;
    position: absolute;
    &.deck__slides--top{
        top: 0;
        right: 0;
    }
    &.deck__slides--bottom{
        top: $h * -0.5;
        @include down {
            top: calc(var(--vh, 1vh) * -50);
        }
        left: 0;
    }
}
.deck__slide{
    position: relative;
    overflow: hidden;
    width: $w;
    height: $h;
    @include down {
        height: calc(var(--vh, 1vh) * 100);
    }
}
.deck__image{
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    // transition: transform 1s 3s linear;
    // &.active{
    //     transition: transform 30s linear;
    //     transform: scale(1.3)
    // }
}
</style>
