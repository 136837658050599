export default class Tick {
    constructor () {
        this.raf = null
        this.collection = []
    }

    start () {
        this.raf = 0
        this.render()
    }

    stop () {
        cancelAnimationFrame(this.raf)
        this.raf = null
    }

    render () {
        this.update()
        if(this.raf !== null) this.raf = requestAnimationFrame(this.render.bind(this))
    }

    update () {
        this.collection.map(item => item())
    }

    add(callback) {
        const index = this.collection.indexOf(callback)
        if (index === -1) this.collection.push(callback)
    }

    remove(callback) {
        const index = this.collection.indexOf(callback)
        if (index != -1) this.collection.splice(index, 1)
    }
}
