// import { } from 'store/mutation-types'
import Breakpoints from '@/js/Breakpoints'
const breakpoints = Breakpoints.value({
	xs: 1,
	sm: 2,
	md: 3,
	lg: 4,
	xl: 5,
})
export default {
    namespaced: true,
    state: {
        breakpoint: breakpoints.value
    },
    mutations: {
        updateBreakpoint(state) {
            state.breakpoint = breakpoints.value;
        }
    },
    getters: {
        mobile: state => state.breakpoint <= 2,
        desktop: state => state.breakpoint > 2
    },
    actions: {
        updateBreakpoint({commit}) {
			commit("updateBreakpoint");
		}
    }
};
