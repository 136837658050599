import MixCharacter from '@/js/MixCharacter'
import Queue from '@/js/Queue'
import Delay from '@/js/Delay'
import CollectionComplete from '@/js/CollectionComplete'
import {shuffle} from '@/js/Matematik'
const LINK_REGEX = /\[([^\]]*)\]\(([^\)]*)\)/;

export default class MixSentence {
	constructor ({parent, sentence} = {}) {
        Object.assign(this, {parent, sentence})
		this.initialize()
	}
	initialize ()  {
		this.setupQueue()
		this.setupCharacters()
		this.setupSentence()
		this.setupMarkup()
	}
	activateUI () {
		this.el.classList.add("active");
	}
	deactivateUI () {
		this.el.classList.remove("active");
	}
	setupQueue () {
		this.q = new Queue()
	}
	setupCharacters () {
		this.characters = []
	}
	setupSentence () {
		this.splittedSentence = this.sentence.split(' ').map(item => {
			let text = item
			const match = new RegExp(LINK_REGEX, "gi").exec(text);
			const type = match ? 'link' : 'word'
			const attr = {}
			if(type === 'link') {
				text = (match[1] || "").replace("&nbsp;", " ");
				attr.href = match[2] || ""
			}
			return {
                type,
                attr,
                chars: text.split("")
            };
		})
	}
	setupMarkup () {
		this.el = document.createElement('div')
		this.el.classList.add('mix-sentence')
		this.parent.appendChild(this.el)
		this.splittedSentence.forEach(({type, chars, attr}, idx) => {
			let divWord = type === 'link' ? document.createElement('a') : document.createElement('div')
			divWord.classList.add("mix-sentence__word");
			this.el.appendChild(divWord);
			if(type === 'link') {
				divWord.href = attr.href;
				divWord.target = '_blank';
				divWord.classList.add("mix-sentence__word--link");
			}

			chars.forEach(char => {
                const divChar = document.createElement("div");
                divChar.classList.add("mix-sentence__char");
                divWord.appendChild(divChar);
                this.createCharacter(divChar, char);
            });
		})
	}
	createCharacter (htmlElement, char) {
		this.characters.push(new MixCharacter(htmlElement, char))
	}
	quickActivate () {
		this.characters.slice().forEach(character => {
			character.show()
		})
	}
	activate ({characters} = {}, complete) {
		
		this.q.flush()
		const chars = characters || this.characters.slice()
		shuffle(chars)
		chars.forEach(character => {
			this.q.add(g => g(done => {
				// character.show()
				character.in()
				Delay.add(0, () => {
					done()
				})
			}))
		})
		this.q.run(complete)
	}
	deactivate ({characters} = {}, complete) {
		this.el.classList.remove("active");
		this.q.flush()
		const chars = characters || this.characters.slice()
		shuffle(chars)
		chars.forEach(character => {
			this.q.add(g => g(done => {
				character.out().then(() => {
					character.hide()
				})
				Delay.add(0, () => {
					done()
				})
			}))
		})
		this.q.run(complete)
	}
	activateFrom ({others} = {}, complete) {
		const maxContestants = 2
		const previousSentence = others.characters.slice()
		const currentSentence = this.characters.slice()
		shuffle(previousSentence)
		shuffle(currentSentence)
		const pairs = []
		for(let i = previousSentence.length - 1; i >= 0; i--) {
			for(let j = 0; j < currentSentence.length; j++) {
				if(pairs.length < maxContestants
				&& !previousSentence[i].char.match(/[ ,.]/)
				&& previousSentence[i].char === currentSentence[j].char) {
					pairs.push({
						replacement: previousSentence.splice(i, 1)[0],
						target: currentSentence.splice(j, 1)[0]
					})
					break;
				}
			}
		}
		this.deactivate({
			characters: previousSentence
		}, () => {
			const completedCheck = CollectionComplete(pairs.length, () => {
				complete()
			})
			const halfCompleteCheck = CollectionComplete(pairs.length - 5, () => {
				this.activate({
					characters: currentSentence
				})
			})
			pairs.forEach((pairs, idx) => {
				const {replacement, target} = pairs
				replacement.to({target}).then(() => {
					replacement.hide()
					target.show()
					halfCompleteCheck()
					completedCheck()
				})
			})
		})
	}
}
