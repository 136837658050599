<template>
    <div class="keyboard" :class="classes">
        <template v-if="setup === 'nav'">
            <div class="keyboard__section">
                <Key char="w" code="w"/>
                <Key char="f" code="f"/>
                <Key char="a" code="a"/>
            </div>
        </template>
        <template v-if="setup === 'gallery'">
            <div class="keyboard__section">
                <Key symbol="up" code="ArrowUp"/>
                <div class="keyboard__break"></div>
                <Key symbol="left" code="ArrowLeft"/>
                <Key symbol="down" code="ArrowDown"/>
                <Key symbol="right" code="ArrowRight"/>
            </div>
        </template>
        <template v-if="setup === 'about'">
            <div class="keyboard__section">
                <Key symbol="up" code="ArrowUp"/>
                <div class="keyboard__break"></div>
                <Key symbol="left" code="ArrowLeft"/>
                <Key symbol="down" code="ArrowDown" disabled/>
                <Key symbol="right" code="ArrowRight"/>
            </div>
        </template>
    </div>
</template>
<script>
import Key from '@/components/Key'
export default {
    name: "Keyboard",
    components: {
        Key
    },
    props: {
        setup: String,
    },
    mixins: [],
    data () {
        return {}
    },
    computed: {
        classes () {
            return {
                'keyboard--arrows': this.arrows
            }
        }
    },
    mounted () {
        this.initial()
        // this.animateIn()
    },
    methods: {
        initial () {
            const elements = document.querySelectorAll('.key')
            TweenMax.set(elements, {
                opacity: 0,
                y: 10,
            })
        },
        animateIn () {
            const elements = document.querySelectorAll('.key')
            TweenMax.staggerTo(elements, 0.5, {
                opacity: .4,
                y: 0,
                clearProps: 'all'
            }, 0.05, () => {
                // TweenMax.to(elements, 0.5, {
                //     opacity: .5,
                //     clearProps: 'all'
                // })
            })
        },
        animateOut () {
            const elements = document.querySelectorAll('.key')
            TweenMax.staggerTo(elements, 0.5, {
                opacity: 0,
                y: 10,
            }, 0.05)
        },
    },
    watch: {}
 }
</script>
<style lang="scss" scoped>
@import "src/scss/index.scss";
.keyboard{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.keyboard__section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.keyboard__break{
    flex: 0 1 100%;
}
</style>