export default {
    work: [
        {
            text: "erik joergensen",
            image: require("@/assets/images/erikjoergensen.jpg"),
            role: "Front-end, Back-end and UX",
            attention: "Created with love at stupid studio",
            link: "https://erik-joergensen.com",
            casestudy: false
        },
        {
            text: "carl nielsen",
            image: require("@/assets/images/carlnielsen.jpg"),
            casestudy: "https://stupid-studio.com/cases/carl-nielsen-international-competition/",
            role: "Front-end, Back-end, UX and Creative",
            attention: "Created with love at stupid studio",
            link: "https://carlnielsencompetition.com/"
        },
        {
            text: "carlsbergs 170",
            image: require("@/assets/images/carlsberg170.jpg"),
            casestudy: "https://stupid-studio.com/cases/carlsberg-brand-experience/",
            role: "Front-end, Back-end and UX",
            attention: "Created with love at stupid studio",
            link: "http://170.carlsberg.com/170"
        },
        {
            text: "cisternerne",
            image: require("@/assets/images/cisternerne.jpg"),
            casestudy: false,
            role: "Front-end, Back-end, Creative and UX",
            attention: "Created with love at stupid studio",
            link: "https://cisternerne.dk"
        },
        {
            text: "future water city",
            image: require("@/assets/images/futurewatercity.jpg"),
            casestudy: false,
            role: 'Front-end, Backend, UX, Creative and "Design"',
            attention: "Created with love at stupid studio",
            link: "https://futurewatercity.com/"
        },
        {
            text: "giv mig svar",
            image: require("@/assets/images/votingforwhat.jpg"),
            casestudy: false,
            role: "Front-end, Back-end",
            attention: "Created with love at stupid studio",
            link: "https://givmigsvar.smplr.dk/"
        },
        {
            text: "deko",
            image: require("@/assets/images/deko.jpg"),
            casestudy: false,
            role: "Front-end and UX",
            attention: "Created with love at stupid studio",
            link: "https://deko.com"
        }
    ],
    fun: [
        {
            text: "glitchy christmas",
            image: require("@/assets/images/glitchychristmas.jpg"),
            casestudy: false,
            role: "Front-end, UX, Creative and Idea",
            attention: "Created with love at stupid studio",
            link: "http://christmas.stupid-studio.com/"
        },
        // {
        //     text: 'dragon',
        //     image: require('@/assets/images/dragon.jpg'),
        //     casestudy: false,
        //     role: 'Front-end, UX and Creative',
        //     attention: 'Created with love at stupid studio',
        //     link: 'http://dragon.smplr.com',
        // },
        {
            text: "the 90s button",
            image: require("@/assets/images/the90sbutton.jpg"),
            casestudy: false,
            role: "Front-end, Back-end, Creative and Idea",
            attention: false,
            link: "http://www.the90sbutton.com"
        },
        {
            text: "bjørnetjenesten",
            image: require("@/assets/images/bjoernetjenesten.jpg"),
            casestudy: false,
            role: "Front-end, Back-end, Radio host and Creator",
            attention: false,
            link: false
            // link: 'https://bjoernetjenesten.dk/',
        },
        {
            text: "stupid moves",
            image: require("@/assets/images/moves.jpg"),
            casestudy: false,
            role: "Front-end, UX, Creative and Idea",
            attention: "Created with love at stupid studio",
            link: "http://moves.stupid-studio.com/#Yz04KzEzKzEwKzE2"
        }
    ]
};
