<template>
	<div id="app">
		<div class="app__content">
			<router-view/>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
export default {
	name: "app",
	components: {},
	data() {
		return {};
	},
	mixins: [],
	computed: {
		...mapGetters("breakpoints", ["mobile", "desktop"])
	},
	mounted() {
		this.events();
	},
	methods: {
		...mapMutations("breakpoints", ["updateBreakpoint"]),
		events() {
            this.resize();
            this.setRealViewportSize();
			window.addEventListener("resize", this.resize);
			window.addEventListener("orientationchange", this.orientationChange);
		},
		resize() {
			this.updateBreakpoint();
		},
		orientationChange() {
			setTimeout(() => this.setRealViewportSize(), 300);
		},
		setRealViewportSize() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		}
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.resize);
		window.removeEventListener("orientationchange", this.orientationChange);
	}
};
</script>
<style lang="scss">
@import "src/scss/index.scss";
.app__content {
	position: relative;
	z-index: $zindex-ground;
    height: 100vh;
	@include down {
		height: calc(var(--vh, 1vh) * 100);
	}
}
.app__keyboard{
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    z-index: 200;
    transform: translateX(-50%);
}
</style>
