export default class Event {
    constructor () {
        this.__callbacks = []
    }
    $on (id, callback) {
        this.__callbacks.push({
            id,
            callback
        })
    }
    $off (id, callback) {
        const event = this.__callbacks.filter(item => {
            return item.callback.toString() === callback.toString() && item.id === id
        })
        for (let i= event.length; i--;) {
            var index = this.__callbacks.indexOf(event[i])
            if (index != -1) this.__callbacks.splice(index, 1);
        }
    }
    $emit (id) {
        const args = [].slice.call(arguments)
        args.shift()
        this.__callbacks.forEach(item => {
            if (item.id === id) item.callback.apply(window, args)
        })
    }
    $once (id) {
        const args = [].slice.call(arguments)
        args.shift()
        this.__callbacks.forEach(item => {
            if (item.id === id) {
                item.callback.apply(window, args)
                this.$off(item.id, item.callback)
            }
        })
    }
}
