export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function css (el, styles) {
    for (var style in styles) {
        el.style[style] = styles[style]
    }
}
export function fl(length, callback) {
    for (let i = 0; i < length; i++) {
        callback(i)
    }
}

export function shade(color, percent) {
	if(!color || !percent) {
		console.warn('Shade -> No color and percent argument')
		return '#000000'
	}
	var f = parseInt(color.slice(1), 16),
		t = percent < 0 ? 0 : 255,
		p = percent < 0 ? percent * -1 : percent,
		R = f >> 16,
		G = f >> 8 & 0x00FF,
		B = f & 0x0000FF;
	return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
}
window.shade = shade


function componentToHex(c) {
	var hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
	if (Array.isArray(r)) {
		b = r[2]
		g = r[1]
		r = r[0]
	}
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16),
	] : null;
}

export function getAbsolutePosition (e) {
    let el = e
    let x = 0
    let y = 0
    while (el) {
        x += el.offsetLeft
        y += el.offsetTop
        el = el.offsetParent
    }
    return {
        x, y
    }
}

export function isTouchDevice () {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    var mq = function(query) {
        return window.matchMedia(query).matches;
    };

    if ("ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
    return mq(query);
}