import Canvas from '@/js/Canvas'
import Particle from '@/js/Particle'
function fl(length, callback) {
    for (let i = 0; i < length; i++) { callback(i) }
}
export default class ParticleSystem {
    constructor ({el} = {}) {
        Object.assign(this, {el})
        this.initialize()
        this.setupParticles()
    }
    initialize () {
        this.particles = []
        this.canvas = new Canvas({el: this.el})
        this.canvas.$on('resized', () => {
            this.destroyParticles()
            this.setupParticles()
        })
    }
    resize () {
        this.canvas.resize()
    }
    setupParticles() {
        fl(this.canvas.width, i => {
            this.particles.push(new Particle({canvas: this.canvas}))
        })
	}
    destroyParticles () {
        this.particles = []
    }
    render() {
		this.canvas.ctx.clearRect(0, 0, this.canvas.realWidth, this.canvas.realHeight);
        this.particles.forEach(particle => particle.render())
	}
}
