import MixSentence from '@/js/MixSentence'
import Delay from '@/js/Delay'
export default class MixController {
    constructor ({parent, sentences} = {}) {
        Object.assign(this, {parent, sentences})
        this.setup()
        this.initialize()
    }
    setup () {
        this.current = 0
        this.mixSentences = []
        this.sentences.forEach((sentence, idx) => {
            this.mixSentences.push(new MixSentence({
                parent: this.parent,
                sentence,
            }))
        })
    }
    initialize () {
        this.mixSentences[this.current].activate({}, () => {
            this.mixSentences[this.current].activateUI();
        });
    }
    set (value, complete) {
        const prev = this.current
        this.current = value
        this.mixSentences[this.current].activateFrom({
            others: this.mixSentences[prev]
        }, complete)
    }
    next (complete) {
        this.mixSentences[this.current].deactivateUI()
        this.current++
        this.current %= this.mixSentences.length
        this.mixSentences[this.current].activateFrom({
            others: this.mixSentences[this.getPrev()]
        }, () => {
            this.mixSentences[this.current].activateUI()
            complete()
        })
    }
    prev (complete) {
        this.mixSentences[this.current].deactivateUI();
        this.current--
        this.current = this.current < 0 ? this.mixSentences.length - 1 : this.current
        this.mixSentences[this.current].activateFrom({
            others: this.mixSentences[this.getNext()]
        }, () => {
            this.mixSentences[this.current].activateUI();
            complete()
        })
    }
    getNext () {
        const c = this.current + 1
        return c % this.mixSentences.length
    }
    getPrev () {
        const c = this.current - 1
        return c < 0 ? this.mixSentences.length - 1 : c
    }
}
