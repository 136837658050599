<template>
    <div class="text-randomizer" :class="elClass">
        <span v-for="string in strings">{{string}}</span>
    </div>
</template>
<script>
import TextRandomizer from '@/js/TextRandomizer'
import Render from '@/js/Render'
export default {
    name: "TextRandomizerTitle",
    components: {
    },
    props: {
        size: Number,
        text: String,
    },
    mixins: [],
    data () {
        return {
            strings: [],
            finish: false,
        }
    },
    computed: {
        elClass () {
            return {
                [`text-randomizer--size${this.size}`] : this.size
            }
        }
    },
    mounted () {
        this.textRandomizer = new TextRandomizer({text: this.text, speed: 0.25})
        this.textRandomizer.$on('updated', this.updated)
        Render.add(this.update)
    },
    methods: {
        update () {
            this.textRandomizer.render()
        },
        updated (e) {
            this.strings = e.string.split('')
        }
    },
    watch: {
        text () {
            this.textRandomizer.transitionToText(this.text, {
                // startTime:this.startTime, changeTime:this.changeTime, stopTime:this.stopTime
            }, () => {
                this.$emit('textTransitioned')
            })
        }
    },
    beforeDestroy() {
        Render.remove(this.update)
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.text-randomizer{
    display: inline-block;
    text-align: left;
    span{
        width: 1em;
        display: inline-block;
        text-align: center;
    }
}
.text-randomizer--size1{
    font-size: rem(50px);
}
.text-randomizer--size2{
    font-size: rem(15px);
    @include up {
        font-size: rem(20px);
    }
    text-transform: uppercase;
}
</style>
