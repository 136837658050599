import Responsive from '@/js/Responsive'
export default new Responsive({
     xs: 0,
     // Small screen / phone
     sm: 576,
     // Medium screen / tablet
     md: 768,
     // Large screen / desktop
     lg: 992,
     // Extra large screen / wide desktop
     xl: 1200
})
