export default class Responsive {
    constructor(breakpoints) {
        this.breakpoints = breakpoints
        this.breakpointCallbacks = {}
        this.state = {}
        this.currentBreakpoint
        for (let key in this.breakpoints) {
            this.state[key] = false
            this.breakpointCallbacks[key] = []
        }
        this.setupEvents()
    }

    setupEvents() {
        this.checkBreakpoint()
        window.addEventListener('resize', () => {
            this.checkBreakpoint()
        })
    }

    checkBreakpoint() {
        const keys = Object.keys(this.state)
        for (let key in this.breakpoints) {
            const i = Object.keys(this.state).indexOf(key);
            const nextBreakpoint = keys.length > i + 1
            const index = nextBreakpoint ? i + 1 : i
            const width = this.breakpoints[keys[index]] - (nextBreakpoint ? 1 : 0)
            const query = nextBreakpoint ? `(min-width: ${this.breakpoints[key]}px) and (max-width: ${width}px)` : `(min-width: ${width}px)`
            this.state[key] = window.matchMedia(query).matches
        }
        for (let key in this.state) {
            if (this.state[key]) {
                if (this.currentBreakpoint != key) {
                    this.emitCallbacks(key)
                }
                if (this.currentBreakpoint === undefined) {
                    setTimeout(() => {
                        this.emitCallbacks(this.currentBreakpoint)
                    })
                }
                this.currentBreakpoint = key
                break
            }
        }
    }

    getProcessedCollection(breakpointValues) {
        let collection = {}
        const keys = Object.keys(this.state)
        if (Object.keys(this.breakpoints).length != Object.keys(breakpointValues).length) {
            for (let key in this.state) {
                const i = Object.keys(this.state).indexOf(key);
                if (breakpointValues[key] != undefined) {
                    collection[key] = breakpointValues[key]
                } else {
                    for (let j = i; j >= 0; j--) {
                        if (breakpointValues[keys[j]] != undefined) {
                            collection[key] = breakpointValues[keys[j]]
                            break;
                        }
                    }
                }
            }
            if (Object.keys(this.breakpoints).length != Object.keys(collection).length) {
                for (let k in this.state) {
                    if (collection[k] === undefined) {
                        for (let j = 0; j < keys.length; j++) {
                            if (collection[keys[j]] != undefined) {
                                collection[k] = collection[keys[j]]
                                break;
                            }
                        }
                    }
                }
            }
        } else {
            collection = breakpointValues
        }
        return collection
    }

    emitCallbacks(currentBreakpointKey) {
        const callbacks = this.breakpointCallbacks[currentBreakpointKey]
        if (callbacks) {
            callbacks.forEach(callback => {
                callback()
            })
        }
    }

    value(breakpointValues) {
        const collection = this.getProcessedCollection(breakpointValues)
        const self = this
        return {
            get value() {
                return collection[self.currentBreakpoint]
            }
        }
    }

    callback(breakpointValues) {
        for (let key in breakpointValues) {
            this.breakpointCallbacks[key].push(breakpointValues[key])
        }
    }

}
