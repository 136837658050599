<template>
    <div class="about">
        <div class="about__background">
            <Snake />
        </div>
        <div class="ui about__ui about__next" @click="next" ref="next">Next</div>
        <div class="ui about__ui about__prev" @click="prev" ref="prev">Prev</div>
        <div class="close ui about__close" @click="close" ref="close">Close</div>
        <div class="app__keyboard" v-if="desktop">
            <KeyboardComponent setup="about" ref="keyboard"/>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import MixController from '@/js/MixController'
import Snake from '@/components/Snake'
import KeyboardComponent from '@/components/Keyboard'
import Keyboard from '@/js/Keyboard'
export default {
    name: "About",
    components: {
        Snake,
        KeyboardComponent,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
            blockKeyDown: false
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['mobile', 'desktop']),
    },
    mounted () {
        this.initialize()
        this.initializeUI()
        this.events()
    },
    methods: {
        initialize () {
            this.mixController = new MixController({parent: this.$el, sentences: [
                "Hi, I’m David. I’m a creative frontend developer with a knack for ux",
                "With 10+ years of experience I’ve created a lot of sturdy interactive solutions",
                "Some would call me a full stack developer but I just call myself a pretty cool guy",
                "I have a strong foundation in JavaScript, HTML, CSS and I love to code with [Vue](https://vuejs.org/)",
                "I also enjoy using [p5.js](https://p5js.org/), [Pixi.js](https://www.pixijs.com/), and other tools to express my creative side",
                "I’m currently having fun at being a Lead frontend developer at [DWARF](https://dwarf.dk/)",
                "I have a bachelor's degree in Interactive Design from [DJMX](https://www.dmjx.dk/)",
                "Previously I've been a Jury member for [CC](https://creativecircle.dk/) and DIA",
                "That's all. Don't be afraid to holla. [@adalberth](https://twitter.com/adalberth)",
            ]})
        },
        initializeUI () {
            const {close, prev, next} = this.$refs
            TweenMax.staggerFromTo([close, prev, next], 1, {
                opacity: 0,
            }, {
                opacity: 1,
                delay: 1,
                ease: Power4.easeInOut,
            }, 0.1)
            if(this.desktop) {
                TweenMax.delayedCall(1.5, () => {
                    this.$refs.keyboard.animateIn()
                })
            }
        },
        events () {
            // window.addEventListener('keydown', this.keydown)
            Keyboard.$on('ArrowLeft/down', this.prev)
            Keyboard.$on('ArrowRight/down', this.next)
            Keyboard.$on('ArrowUp/down', this.close)
        },
        // keydown (e) {
        //     // if(this.blockKeyDown) return
        //     // this.blockKeyDown = true
        //     const code = e.keyCode
        //     if(code == 37) { // left
        //         this.prev()
        //     } else if(code == 38) { // up
        //         this.close()
        //     } else if(code == 39) { // right
        //         this.next()
        //     } else if(code == 40) { // down
        //     }
        // },
        prev () {
            if(this.blockKeyDown) return
            this.blockKeyDown = true
            this.mixController.prev(() => this.blockKeyDown = false)
        },
        next () {
            if(this.blockKeyDown) return
            this.blockKeyDown = true
            this.mixController.next(() => this.blockKeyDown = false)
        },
        close () {
            if(this.blockKeyDown) return
            this.blockKeyDown = true
            TweenMax.to(this.$el, 1, {
                opacity: 0,
                scale: 0.75,
                onComplete: () => this.$router.push({name: 'Home'})
            })
        }
    },
    watch: {
    },
    beforeDestroy() {
        // window.removeEventListener('keydown', this.keydown)
        Keyboard.$off('ArrowLeft/down', this.prev)
        Keyboard.$off('ArrowRight/down', this.next)
        Keyboard.$off('ArrowUp/down', this.close)
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.about{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 1rem;
    @include down {
        font-size: .5rem;
    }
}
.about__background{
    position: absolute;
    left: 0rem;
    top: 0rem;
    width: 100%;
    height: 100%;
    background-size: 1em 2.25em, 3em 1em, 1em 2.25em, 3em 1em;
    background-position: 0em calc(0.35em + 1.125em), calc(0.35em + 1.5em) 0em, 0em 1.125em, 1.5em 0em;
    background-image:
    linear-gradient(rgba($color-black, 1) calc(2.25em - 0.6em), transparent em(1px)),
    linear-gradient(90deg, rgba($color-black, 1) calc(3em - 0.6em), transparent em(1px)),
    linear-gradient(rgba(white, 0.05) em(1px), transparent em(1px)),
    linear-gradient(90deg, rgba(white, 0.05) em(1px), transparent em(1px));
}
/deep/ .mix-sentence{
    font-size: 3em;
    line-height: 1.5;
    max-width: 50%;
    position: absolute;
    left: 3.5em;
    top: 2.25em;
    pointer-events: none;
    &.active{
        z-index: 100;
        pointer-events: auto;
    }
    @include down {
        max-width: 100%;
        top: 3em;
        left: 1.5em;
    }
}
/deep/ .mix-sentence__word{
    display: inline-block;
    white-space: nowrap;
    padding-right: 1em;
    position: relative;
}
/deep/ .mix-sentence__word--link{
    &:before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 1em);
        height: 100%;
        background-color: darken($color-white, 85);
        pointer-events: none;
        transition: opacity 1s ease;
        opacity: 0;
    }
    &:hover{
        @include up {
            color: $color-black;
            &:before{
                background-color: $color-white;
            }
        }
    }
}
/deep/ .mix-sentence.active .mix-sentence__word--link:before{
    opacity: 1;
}

/deep/ .mix-sentence__char{
    display: inline-block;
    width: 1em;
    text-align: center;
}
.about__ui{
    position: absolute;
    bottom: 1rem;
    @include down {
        bottom: 0rem;
    }
}
.about__next{
    right: 1rem;
    z-index: 100;
    @include down {
        right: 0rem;
    }
}
.about__prev{
    left: 1rem;
    z-index: 100;
    @include down {
        left: 0rem;
    }
}
</style>
