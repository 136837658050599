class InteractiveState {
    constructor () {
        this._uiActive = false
        this._scrollActive = false
    }
    set ScrollActive (value) {
        this._scrollActive = value
    }
    get ScrollActive () {
        return this._scrollActive
    }
    set UIActive (value) {
        this._uiActive = value
    }
    get UIActive () {
        return this._uiActive
    }
    
}
export default new InteractiveState()