<template>
    <div class="gallery" @mousemove="mousemoveHandler">
        <div class="gallery__actions" v-if="desktop">
            <div class="gallery__action gallery__action__left" @click="leftHandler" :style="leftStyle"></div>
            <div class="gallery__action gallery__action__right" @click="rightHandler" :style="rightStyle"></div>
        </div>
        <div class="gallery__decks" ref="decks">
            <Deck :slides="slidesTop" top :index="index" ref="deckTop" @complete="deckAnimationCompleted"/>
            <Deck :slides="slidesBottom" bottom :index="index" ref="deckBottom"/>
        </div>
        <div class="gallery__info">
            <div class="gallery__info__content">
                <div class="gallery__info__content__title">
                    <TextRandomizerTitle :text="text" :size="2" />
                </div>
                <div class="gallery__info__content__credit" v-if="credit" ref="credit">
                    <p v-if="credit.role">{{credit.role}}</p>
                    <p>{{credit.attention || '&nbsp;'}}</p>
                    <p>
                        <a v-if="credit.link" :href="credit.link" target="_blank">visit site</a>
                        <span v-if="credit.casestudy">&nbsp;&nbsp;&nbsp;</span>
                        <a v-if="credit.casestudy" :href="credit.casestudy" target="_blank">Case study</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="close ui" ref="close" @click="close">Close</div>
        <div class="app__keyboard" v-if="desktop">
            <KeyboardComponent setup="gallery" ref="keyboard"/>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import Deck from '@/components/Deck'
import TextRandomizerTitle from '@/components/TextRandomizerTitle'
import {mapConstrain} from '@/js/Matematik'
import Interactive from '@/js/Interactive'
import InteractiveState from '@/js/InteractiveState'
import KeyboardComponent from '@/components/Keyboard'
import Keyboard from '@/js/Keyboard'
export default {
    name: "Gallery",
    components: {
        Deck,
        TextRandomizerTitle,
        KeyboardComponent,
    },
    props: {
        slides: Array
    },
    mixins: [],
    data () {
        return {
            index: 0,
            ready: false,
            blockKeydown: false,
            credit: {
                role: false,
                link: false,
                casestudy: false,
                attention: false,
            },
            interactive: null,
            position: 0,
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['mobile','desktop']),
        slidesTop () { return this.slides.map(project => project.image).slice().reverse() },
        slidesBottom () { return this.slides.map(project => project.image) },
        currentSlide () { return this.ready && this.slides[this.index] },
        text () { return this.currentSlide && this.currentSlide.text || '               ' },
        link () { return this.currentSlide && this.currentSlide.link},
        role () { return this.currentSlide && this.currentSlide.role },
        attention () { return this.currentSlide && this.currentSlide.attention },
        casestudy () { return this.currentSlide && this.currentSlide.casestudy },
        leftStyle () {
            return this.index > -1 && {
                cursor: 'pointer'
            }
        },
        rightStyle () {
            return this.index < this.slides.length && {
                cursor: 'pointer'
            }
        },
    },
    mounted () {
        this.beforeInitialize()
        this.initialize()
        this.events()
    },
    methods: {
        beforeInitialize () {
            const {decks, credit, close} = this.$refs
            TweenMax.set(decks, {
                scale: 0.75,
                force3D: true,
            })
            TweenMax.set([credit, close], {
                opacity: 0,
            })
        },
        initialize () {
            const {decks, credit, close, keyboard} = this.$refs
            TweenMax.to(decks, 1, {
                delay: 1.5,
                scale: 1,
                ease: Power4.easeIn,
                force3D: true,
                onComplete: () => {
                    this.ready = true
                    this.setCredit()
                }
            })
            TweenMax.to([credit, close], 2, {
                opacity: 1,
                delay: 2,
            })
            if(this.desktop) {
                TweenMax.delayedCall(3, () => {
                    keyboard.animateIn()
                })
            }
        },
        events () {
            const {deckTop, deckBottom} = this.$refs
            deckTop.$on('complete', () => {
                if(this.index <= -1 || this.index >= this.slides.length) {
                    this.$router.push({name: 'Home'})
                }
                this.blockKeydown = false
            })
            

            // window.addEventListener('keydown', this.keydown)
            Keyboard.$on('ArrowLeft/down', this.leftHandler)
            Keyboard.$on('ArrowRight/down', this.rightHandler)
            Keyboard.$on('ArrowDown/down', this.openLink)
            Keyboard.$on('ArrowUp/down', this.close)

            if(this.mobile) {
                this.interaction = new Interactive(this.$el)
                this.interaction.$on('progress', this.touchmoveHandler)
            }
        },
        openLink () {
            window.open(this.link, '_blank')
        },
        // keydown (e) {
        //     if(this.blockKeydown) return
        //     this.blockKeydown = true
        //     const code = e.keyCode
        //     console.log('kldsfmdslkfmsdlk')
        //     if(code == 37) { // left arrow key
        //         this.leftHandler()
        //     } else if(code == 39) { // right arrow key
        //         this.rightHandler()
        //     } else if(code == 40) { // down arrow key
        //         window.open(this.link, '_blank')
        //         this.blockKeydown = false
        //     } else if(code == 27 || code == 38) { // esc || up arrow key
        //         this.close()
        //     }
        // },
        close () {
            TweenMax.to(this.$el, 1, {
                opacity: 0,
                scale: 0.75,
                onComplete: () => this.$router.push({name: 'Home'})
            })
        },
        mousemoveHandler (e) {
            if(this.mobile) return
            const {deckTop, deckBottom} = this.$refs
            const h = window.innerWidth * 0.5
            const a = e.clientX - h
            const x = mapConstrain(Math.abs(a), window.innerWidth * 0.25, h, 0, 1) * Math.sign(a)
            if(this.index === -1 && x < 0
            || this.index === this.slides.length && x > 0) return
            deckTop.offset(x)
            deckBottom.offset(x)
        },
        touchmoveHandler(e) {
            const {deckTop, deckBottom} = this.$refs
            const {x: xPos, y: yPos, position: touchPosition} = e
            const aboveOrBelow = touchPosition.y < window.innerHeight * .5 ? -1 : 1
            this.position += (xPos * -1) * aboveOrBelow
            const x = mapConstrain(Math.abs(this.position), 0, window.innerWidth, 0, 1) * Math.sign(this.position)
            if(x > .95) {
                this.rightHandler()
            } else if(x < -.95) {
                this.leftHandler()
            }
            if(this.index === -1 && x < 0
            || this.index === this.slides.length && x > 0) return
            deckTop.move(x)
            deckBottom.move(x)
        },
        leftHandler () {
            const {deckTop} = this.$refs
            if(deckTop.animating) return
            this.index--
            this.index = Math.max(this.index, -1)
        },
        rightHandler () {
            const {deckTop} = this.$refs
            if(deckTop.animating) return
            this.index++
            this.index = Math.min(this.index, this.slides.length)
        },
        setCredit () {
            this.credit = {
                role: this.role,
                link: this.link,
                casestudy: this.casestudy,
                attention: this.attention,
            }
        },
        deckAnimationCompleted () {
            this.position = 0
            TweenMax.delayedCall(1, () => {
                InteractiveState.UIActive = false
            })
        }
    },
    watch: {
        index () {
            const {decks, credit, close} = this.$refs
            InteractiveState.UIActive = true
            if(this.desktop) {
                TweenMax.to(decks, 1, {
                    scale: 0.75,
                    ease: Power4.easeOut,
                    force3D: true,
                })
                TweenMax.to(decks, 1, {
                    delay: 1.5,
                    scale: 1,
                    ease: Power4.easeIn,
                    force3D: true,
                })
            }

            TweenMax.to([credit, close], 0.5, {
                opacity: 0,
                onComplete: () => {
                    this.setCredit()
                }
            })
            TweenMax.to([credit, close], 1, {
                opacity: 1,
                delay: 3,
            })
        }
    },
    beforeDestroy() {
    //   window.removeEventListener('keydown', this.keydown)
        Keyboard.$off('ArrowLeft/down', this.leftHandler)
        Keyboard.$off('ArrowRight/down', this.rightHandler)
        Keyboard.$off('ArrowDown/down', this.openLink)
        Keyboard.$off('ArrowUp/down', this.close)
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.gallery{
    &:before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25%;
        pointer-events: none;
        background: linear-gradient(rgba($color-black, 0.5), rgba($color-black, 0));
        opacity: 1;
        z-index: 100;
    }
}
.gallery__info{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 1rem;
    width: 100%;
    @include up {
        padding: 2rem;
    }
    &:before{
        content:"";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200%;
        pointer-events: none;
        background: linear-gradient(rgba($color-black, 0), rgba($color-black, .5));
        opacity: 1;
    }
}
.gallery__info__content{
    position: relative;
    z-index: 100;
    @include up {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}
.gallery__info__content__credit{
    @include up {
        text-align: right;
    }
    p:last-child{
        margin-bottom: 0;
    }
}
.gallery__actions{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: space-between;
}
.gallery__action{
    flex: 0 0 25%;
}
</style>
