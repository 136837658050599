export default function CollectionComplete (length, complete) {
    let count = 1
    let called = false
    return () => {
        if(count >= length && count > 0) {
            count = 0
            if(complete) complete()
        } else {
            count++
        }
    }
}
