import Render from '@/js/Render'
class Delay {
    constructor () {
        this.delays = []
        this.hash = {}
        Render.add(this.render.bind(this))
    }
    render () {
        const now = Date.now()
        this.delays.filter(delay => {
            return delay.time <= now
        }).forEach(delay => {
            if(delay.callback) delay.callback()
            const idx = this.delays.indexOf(delay)
            if(idx != -1) this.delays.splice(idx, 1)
            if(delay.hash) {
                const idxh = this.hash[delay.hash] && this.hash[delay.hash].indexOf(delay)
                if (idxh > -1) this.hash[delay.hash].splice(idxh, 1)
            }
        })
    }
	add(time, callback, hash=false){
		var delay = {time: (time * 1000) + Date.now(), callback, hash}
		this.delays.push(delay)
        if(hash) {
            if(!this.hash[hash]) this.hash[hash] = []
            this.hash[hash].push(delay)
        }
	}
    killAll () {
        this.delays = []
        this.hash = {}
    }
    kill(hash) {
        if(this.hash[hash]) {
            this.hash[hash].forEach(delay => {
                const idx = this.delays.indexOf(delay)
                if (idx > -1) this.delays.splice(idx, 1)
            })
            delete this.hash[hash]
        }
    }
}
export default new Delay()
